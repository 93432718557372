<template>
  <div class="auth">
    <h1>Login</h1>

    <form @submit="signIn" class="form" method="post">
      <div class="form-wrap form-wrap-first">
        <input v-model="user.email" class="form-input" type="email" name="email" id="email" placeholder="E-Mail" />
      </div>

      <div class="form-wrap form-wrap-last">
        <span
          ><router-link to="/auth/password">{{ $t("auth.passwordLost") }}</router-link></span
        >
        <input v-model="user.password" class="form-input" type="password" name="password" id="password" placeholder="Passwort" />
      </div>

      <p style="text-align: right">
        <input type="submit" :value="$t('auth.loginButton')" class="button button-ci button-round button-100" />
      </p>

      <div class="auth-meta">
        <p>{{ $t("auth.noAccount") }}</p>
        <p class="sign_up">
          <router-link to="/auth/sign_up" class="link">{{ $t("auth.createAccount") }}</router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<style>
.form-wrap {
  position: relative;
}

.form-wrap span a {
  color: #91979f;
  font-weight: 600;
}
</style>

<script>
import axios from "axios";
import router from "../../router";

export default {
  name: "auth-sign-in",
  data() {
    return {
      errors: [],
      user: {
        email: "",
        password: "",
      },
      email_auth: false,
    };
  },
  methods: {
    signIn: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/auth/sign_in",
          {
            email: this.user.email,
            password: this.user.password,
          },
          { headers: {} }
        )
        .then((response) => {
          console.log(response.headers);
          localStorage.setItem("oo_token", response.headers.authorization);
          this.$store.commit("addToken", response.headers.authorization);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("auth.loginSuccess"),
          });
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            text: error.response.data.errors[0],
          });
        });
    },
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      router.push("/dashboard");
    }
  },
};
</script>
